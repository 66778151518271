

import { computed, defineComponent, ref } from 'vue';
import { Workflow } from '@/model/Workflow';
import optionsManager from '@/services/OptionsManager';

function copyLink(id: string, event: Event) {
  const url = `${window.location.protocol}://${window.location.host}?workflowId=${id}&participantId=PARTICIPANTID`;
  navigator.clipboard.writeText(url);

  const target = event?.target as (HTMLButtonElement | undefined);
  if (target) {
    target.classList.add('is-success');
    const originalText = target.innerText;
    target.innerText = 'Copied!';
    target.disabled = true;

    setTimeout(() => {
      if (target) {
        target.classList.remove('is-success');
        target.innerText = originalText;
        target.disabled = false;
      }
    }, 1000);
  }
}

export default defineComponent({
  name: 'MainMenuWorkflowCard',
  emits: ['workflow-selected'],
  props: {
    workflow: {
      type: Object as () => Workflow,
      required: true,
    },
  },
  setup(props, ctx) {
    return {
      isDebug: optionsManager.isDebug,
      copyLink,
    };
  },
});

