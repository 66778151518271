
import {
  computed, defineComponent, nextTick, ref,
} from 'vue';
import webcamProvider from '@/services/WebcamProvider';
import VideoDatabaseEntry from '@/model/VideoDatabaseEntry';
import WorkflowMenu from '@/components/screens/WorkflowMenu.vue';
import CreateWorkflowScreen from '@/components/screens/CreateWorkflowScreen.vue';
import MiniLessonPlayer from '@/components/elements/MiniLessonPlayer.vue';
import KeyframeSelectorTool from '@/components/tools/KeyframeSelectorTool.vue';
import CameraSurface from './components/CameraSurface.vue';
import OnboardingUI from './components/OnboardingUI.vue';
import MainMenu from './components/screens/MainMenu.vue';
// import LearningScreen from './components/screens/LearningScreen.vue';
import MiniLesson from './model/MiniLesson';

import CreateLessonScreen from './components/screens/CreateLessonScreen.vue';
import workflowManager from './services/WorkflowManager';
import optionsManager from './services/OptionsManager';

const State = {
  MainMenu: 'MainMenu',
  LessonActive: 'LessonActive',
  WorkflowActive: 'WorkflowActive',
  CreateLesson: 'CreateLesson',
  CreateWorkflow: 'CreateWorkflow',
  KeyframeSelectorTool: 'KeyframeSelectorTool',
};

export default defineComponent({
  name: 'App',
  components: {
    MainMenu,
    WorkflowMenu,
    KeyframeSelectorTool,
    CreateLessonScreen,
    CreateWorkflowScreen,
    MiniLessonPlayer,
  },
  setup() {
    const state = ref(State.MainMenu);
    const cameraSurface = ref(null as typeof CameraSurface | null);
    const hasCompletedOnboarding = ref(false);

    const currentVideo = ref(null as VideoDatabaseEntry | null);
    const currentLesson = ref(null as MiniLesson | null);
    const keyframes = ref([] as number[]);

    function lessonSelected(videoEntry: VideoDatabaseEntry, lesson: MiniLesson) {
      currentVideo.value = videoEntry;
      currentLesson.value = lesson;

      state.value = State.LessonActive;
      // if (webcamProvider.webcamStatus.value === 'running') state.value = State.LessonActive;
      // else state.value = State.PromptStartWebcam;
    }

    function startKeyframeSelection(videoEntry: VideoDatabaseEntry, kfs: number[]) {
      console.log('startKeyframeSelection', videoEntry, kfs);
      keyframes.value = kfs;
      currentVideo.value = videoEntry;
      state.value = State.KeyframeSelectorTool;
    }

    function goHome() {
      state.value = State.MainMenu;
      currentVideo.value = null;
      currentLesson.value = null;
    }

    return {
      currentVideo,
      currentLesson,
      lessonSelected,
      goHome,
      keyframes,
      state,
      cameraSurface,
      State,
      startKeyframeSelection,
      startedWithWorkflow: ref(false),
      isDebug: optionsManager.isDebug,
      participantId: optionsManager.participantId,
    };
  },
  mounted() {
    // If app was launched with a workflowId, skip straight
    // to that workflow
    if (optionsManager.workflowId.value) {
      if (this.startWorkflow(optionsManager.workflowId.value)) {
        this.startedWithWorkflow = true;
      }
    }
  },
  methods: {
    createLessonSelected(dance: VideoDatabaseEntry) {
      this.currentVideo = dance;
      this.state = State.CreateLesson;
    },
    startWorkflow(workflowId: string) {
      console.log('Starting workflow', workflowId);
      if (workflowManager.setActiveFlow(workflowId)) {
        this.state = State.WorkflowActive;
        return true;
      }
      return false;
    },
  },
});
